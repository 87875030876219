import { ChartDataset, ScatterDataPoint } from 'chart.js';
import { startOfWeek } from 'date-fns';
import type {
  BudgetDocument,
  DataPoint,
  TransactionDocumentWithId,
} from '@og-shared/types';
import {
  currencyNumber,
  formatDate,
  getDateAtMidnight,
  IGNORE_BUDGET_ID,
  dictionaryToArray,
  exhaustiveCheck,
  keys,
  sortTransactions,
} from '@og-shared/utils';

import { getTotalSpendingForBudgetFromTransactions } from '../components/pages/budget-report-page/budget-report-page-utils';
import { getColor, getTextColor, primaryColor } from './colors';
import { getHorizontalLineDataset } from './horizontal-line';

export function getDatasetFromPastTransactions(params: {
  budget: Pick<BudgetDocument, 'name' | 'color' | 'budget_id'> | undefined;
  groupBy: 'week' | 'month' | 'day' | 'year';
  horizontalLine: number;
  transactions: TransactionDocumentWithId[];
  startDate: string;
  endDate: string;
}) {
  const { budget, groupBy, horizontalLine, transactions, startDate, endDate } =
    params;
  const label = budget?.name;
  const lineColor = budget?.color;
  const budgetId = budget?.budget_id;

  const color = getColor(lineColor, 'primary') || primaryColor;

  const dateMap = getDateMapFromTransactions({
    transactions,
    groupBy,
    includeIgnore: true,
    startDate,
    endDate,
  });

  const data: DataPoint[] = [];
  const dates = keys(dateMap).sort((a, b) => (a > b ? 1 : -1));

  dates.map(dateString => {
    const amount = getTotalSpendingForBudgetFromTransactions({
      budgetId,
      transactions: dateMap[dateString].transactions,
    });
    const date = getDateAtMidnight(dateString);
    const pastDataPoint: DataPoint = {
      x: date.getTime(),
      y: currencyNumber(Math.abs(amount)),
    };
    data.push(pastDataPoint);
    return {
      date,
      transactions: dateMap[dateString].transactions,
      amount,
    };
  });

  const dataset: ChartDataset<'bar', ScatterDataPoint[]> = {
    type: 'bar',
    label,
    data,
    backgroundColor: color,
    borderColor: color,
  };

  const horizontalLineData = getHorizontalLineDataset({
    data: data.map(d => ({ x: d.x, y: Math.abs(horizontalLine) })),
    label,
    color: getTextColor(),
  });
  return {
    datasets: [horizontalLineData, dataset],
  };
}

function getDateMapFromTransactions(params: {
  transactions: TransactionDocumentWithId[];
  groupBy: 'month' | 'day' | 'week' | 'year';
  includeIgnore: boolean;
  startDate: string;
  endDate: string;
  budgetId?: string;
}) {
  const { transactions, groupBy, includeIgnore, budgetId, startDate, endDate } =
    params;
  const startDateString = getGroupByKeyFromDate(groupBy, startDate);
  const endDateString = getGroupByKeyFromDate(groupBy, endDate);

  const dateMap: {
    [date: string]: {
      date: Date;
      transactions: TransactionDocumentWithId[];
      cashFlow: number;
      income: number;
      expenses: number;
    };
  } = {
    [startDateString]: {
      date: getDateAtMidnight(startDateString),
      transactions: [],
      cashFlow: 0,
      income: 0,
      expenses: 0,
    },
    [endDateString]: {
      date: getDateAtMidnight(endDateString),
      transactions: [],
      cashFlow: 0,
      income: 0,
      expenses: 0,
    },
  };
  transactions.map(transaction => {
    const dateString = transaction.date;
    const key = getGroupByKeyFromDate(groupBy, dateString);
    if (!dateMap[key]) {
      dateMap[key] = {
        date: getDateAtMidnight(key),
        transactions: [],
        cashFlow: 0,
        income: 0,
        expenses: 0,
      };
    }
    transaction.budgets.map(split => {
      const amount = split.amount;
      if (budgetId) {
        if (
          split.budget_id === budgetId ||
          split.child_budget_id === budgetId
        ) {
          dateMap[key].cashFlow = dateMap[key].cashFlow + amount;
          if (amount > 0) {
            dateMap[key].income = dateMap[key].income + amount;
          } else {
            dateMap[key].expenses = dateMap[key].expenses + amount;
          }
          return;
        } else {
          return;
        }
      }
      if (split.budget_id === IGNORE_BUDGET_ID) {
        if (!includeIgnore) return;
      }
      dateMap[key].cashFlow = dateMap[key].cashFlow + amount;
      if (amount > 0) {
        dateMap[key].income = dateMap[key].income + amount;
      } else {
        dateMap[key].expenses = dateMap[key].expenses + amount;
      }
    });

    dateMap[key].transactions.push(transaction);
  });
  keys(dateMap).map(date => {
    dateMap[date].transactions = dateMap[date].transactions.sort((a, b) =>
      sortTransactions(a, b) === 0
        ? Math.abs(b.amount) - Math.abs(a.amount)
        : sortTransactions(a, b)
    );
  });
  return dateMap;
}

export function getGroupedTransactions(params: {
  transactions: TransactionDocumentWithId[];
  groupBy: 'month' | 'day' | 'week' | 'year';
  includeIgnore: boolean;
  startDate: string;
  endDate: string;
  budgetId?: string;
}) {
  const { transactions, groupBy, includeIgnore, budgetId, startDate, endDate } =
    params;
  const dateMap = getDateMapFromTransactions({
    transactions,
    groupBy,
    includeIgnore,
    budgetId,
    startDate,
    endDate,
  });
  const transactionsByDateGroup = dictionaryToArray(dateMap).sort((a, b) =>
    a.date > b.date ? -1 : 1
  );
  return transactionsByDateGroup;
}

export function getGroupByKeyFromDate(
  groupBy: 'month' | 'day' | 'week' | 'year',
  dateString: string
) {
  switch (groupBy) {
    case 'month': {
      const monthYear = dateString.slice(0, 7);
      return `${monthYear}-01`;
    }
    case 'day': {
      return dateString;
    }
    case 'week': {
      const weekStart = startOfWeek(getDateAtMidnight(dateString));
      return formatDate(weekStart);
    }
    case 'year': {
      const yearString = dateString.slice(0, 4);
      return `${yearString}-01-01`;
    }
    default: {
      return exhaustiveCheck(groupBy);
    }
  }
}
